import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { getResellersCustomersList } from "../../utils/api";
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";

const ResellersCustomers = () => {
  const [customerList, setCustomerList] = useState([]);
  const [filteredCustomerList, setFilteredCustomerList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchCustomerList() {
      try {
        setLoading(true);
        const data = await getResellersCustomersList();
        setCustomerList(data);
        setFilteredCustomerList(data);
      } catch (error) {
        console.error(
          "Failed to fetch resellers customers list:",
          error.message
        );
      } finally {
        setLoading(false);
      }
    }

    fetchCustomerList();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    const filteredList = customerList.filter((customer) =>
      customer.company_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCustomerList(filteredList);
  };

  const handleReset = () => {
    setSearchInput("");
    setFilteredCustomerList(customerList);
  };

  const handleSort = (column) => {
    let sortedList = [...filteredCustomerList];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setFilteredCustomerList(sortedList);
  };

  const handleViewDetails = (customer) => {
    setSelectedCustomer(customer);
    setShowViewModal(true);
  };

  const handleCloseModal = () => {
    setShowViewModal(false);
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Resellers Customers Lists</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search by reseller company name..."
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
            </div>
            <br />
            <div className="table-responsive">
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("customer_name")}
                    >
                      Customer Name
                      {sortColumn === "customer_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("customer_company_name")}
                    >
                      Customer Company Name
                      {sortColumn === "customer_company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("customer_domainname")}
                    >
                      Customer Domain Name
                      {sortColumn === "customer_domainname" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th
                      className="sortable-header"
                      onClick={() => handleSort("company_name")}
                    >
                      Reseller Company Name
                      {sortColumn === "company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCustomerList.map((customer) => (
                    <tr
                      key={customer.cust_id}
                      onClick={() => handleViewDetails(customer)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{customer.customer_name}</td>
                      <td>{customer.customer_company_name}</td>
                      <td>{customer.customer_domainname}</td>
                      <td>{customer.company_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Viewing Customer Details */}
      <Modal show={showViewModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Customer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCustomer && (
            <div>
              <p>
                <strong>Customer Name:</strong> {selectedCustomer.customer_name}
              </p>
              <p>
                <strong>Customer Email:</strong>{" "}
                {selectedCustomer.customer_emailid}
              </p>
              <p>
                <strong>Customer Company Name:</strong>{" "}
                {selectedCustomer.customer_company_name}
              </p>
              <p>
                <strong>Customer Domain Name:</strong>{" "}
                {selectedCustomer.customer_domainname}
              </p>
              <p>
                <strong>Reseller Company Name:</strong>{" "}
                {selectedCustomer.company_name}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default ResellersCustomers;
