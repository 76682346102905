import React, { Fragment, useState, useEffect } from "react";
import {
  get_logininvitationparameter,
  send_logininvotation,
} from "../../utils/api"; // Import the API function
import DepartmentModuleManagement from "./departmentmodulemanagement";
import { Modal } from "react-bootstrap";

const LogininvitationManagement = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    adminName: "",
    adminEmailID: "",
    role: "",
    department: "",
    modules: [],
    region: "",
  });

  const [roleOptions, setRoleOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [showDeptModManageModal, setShowDeptModManageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const regionOptions = ["north", "east", "west", "south"]; // Region dropdown values

  useEffect(() => {
    const fetchParameters = async () => {
      try {
        setLoading(true);
        const responseData = await get_logininvitationparameter();
        setRoleOptions(responseData.data.data_result[0].role_names);
        setDepartmentOptions(responseData.data.data_result[0].department_name);
        setModuleOptions(responseData.data.data_result[0].modules_name);
      } catch (error) {
        console.error("Error fetching login invitation parameters:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchParameters();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleShowDeptModManageModal = () => {
    setShowDeptModManageModal(true);
  };

  const handleCloseDeptModManageModal = () => {
    setShowDeptModManageModal(false);
  };
  const handleModuleClick = (moduleName) => {
    setFormData((prevData) => {
      const modules = prevData.modules.includes(moduleName)
        ? prevData.modules.filter((module) => module !== moduleName)
        : [...prevData.modules, moduleName];
      return {
        ...prevData,
        modules,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await send_logininvotation(
        formData.adminName,
        formData.adminEmailID,
        formData.role,
        formData.department,
        formData.modules.join(","),
        formData.region
      );
      if (response.status === 200) {
        const data = await response.json();
        window.alert(data.message);
        // Reset form data
        setFormData({
          adminName: "",
          adminEmailID: "",
          role: "",
          department: "",
          modules: [],
          region: "",
        });
        handleClose();
      } else {
        window.alert("Error for sending the Invitation");
      }
    } catch (error) {
      console.error("Error sending Invitation:", error);
      // Handle error, e.g., show an error message to the user
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Admin Deligation</h4>
            <br />
            <form className="form-sample" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Admin Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Admin Name"
                        name="adminName"
                        value={formData.adminName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Admin EmailID<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Admin EmailID"
                        name="adminEmailID"
                        value={formData.adminEmailID}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Role<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Role</option>
                        {roleOptions.map((role, index) => (
                          <option key={index} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {formData.role === "Account Manager" && (
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Region<span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-9">
                        <select
                          className="form-control"
                          name="region"
                          value={formData.region}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Region</option>
                          {regionOptions.map((region, index) => (
                            <option key={index} value={region}>
                              {region}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">
                      Department<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        name="department"
                        value={formData.department}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Department</option>
                        {departmentOptions.map((department, index) => (
                          <option key={index} value={department}>
                            {department}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="scroll-container"
                  style={{
                    overflowY: "auto",
                    height: "350px",
                    width: "100%",
                    border: "1px solid #000",
                    padding: "10px",
                  }}
                >
                  <div
                    className="modules"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      gap: "10px",
                      marginBottom: "20px",
                      width: "80%",
                      margin: "auto", // Center the .modules container
                    }}
                  >
                    {moduleOptions.map((moduleName, index) => {
                      const isSelected = formData.modules.includes(moduleName);
                      return (
                        <div
                          key={index}
                          className="module"
                          style={{
                            border: `3px solid ${
                              isSelected ? "green" : "#000"
                            }`,
                            padding: "10px",
                            textAlign: "center",
                            cursor: "pointer",
                            minWidth: "100px",
                            gap: "5px",
                            color: isSelected ? "green" : "inherit",
                            borderRadius: "30px", // Add this line to make the box rounded
                          }}
                          onClick={() => handleModuleClick(moduleName)}
                        >
                          {moduleName}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <br></br>
              <button type="submit" className="btn btn-primary">
                Send Invitation
              </button>
              <button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={handleShowDeptModManageModal}
              >
                Add Department/Module/Role
              </button>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={showDeptModManageModal}
        onHide={handleCloseDeptModManageModal}
        // size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Department/Module/Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DepartmentModuleManagement
            handleClose={handleCloseDeptModManageModal}
          />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleCloseDeptModManageModal}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default LogininvitationManagement;
