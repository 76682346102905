import React, { Fragment, useState, useEffect } from "react";
import {
  getCustomerQuotationlist,
  placeOrder,
  getCustomerlist,
  mapQuotation,
  getCasesnames,
  getVoucherAmount,
} from "../../utils/api"; // Assuming you have an API function for getting customer cases list
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select"; // Import react-select
import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UserQuotationDetails = () => {
  const [quotationList, setQuotationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [initialUserList, setInitialUserList] = useState([]);
  const [showQuotationModal, setShowQuotationModal] = useState(false);
  const [showMapQuotationModal, setShowMapQuotationModal] = useState(false);
  const [selectedQuotationDetails, setSelectedQuotationDetails] =
    useState(null);
  const [customerList, setCustomerList] = useState([]); // State to hold customer list
  const [selectedQuotationId, setSelectedQuotationId] = useState(null); // State to hold selected quotation ID
  const [caseNames, setCaseNames] = useState([]);
  const [selectedCaseName, setSelectedCaseName] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherAmount, setVoucherAmount] = useState(0);
  const [showVoucherInput, setShowVoucherInput] = useState(false);
  const [originalCommission, setOriginalCommission] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [voucherType, setVoucherType] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState("");

  // Compute total commission when relevant data changes
  useEffect(() => {
    if (selectedQuotationDetails) {
      setTotalCommission(calculateTotalCommission("regular"));
    }
  }, [selectedQuotationDetails]);
  const loginDataFromStorage = localStorage.getItem("userDetails");
  const parsedLoginData = JSON.parse(loginDataFromStorage);
  const [voucherApplied, setVoucherApplied] = useState(false);
  useEffect(() => {
    const fetchQuotationList = async () => {
      setLoading(true);
      try {
        setLoading(true);
        const loginDataFromStorage = localStorage.getItem("userDetails");
        if (loginDataFromStorage) {
          const parsedLoginData = JSON.parse(loginDataFromStorage);
          const email = parsedLoginData.reseller_email;
          const data = await getCustomerQuotationlist(email);
          setQuotationList(data);
          setInitialUserList(data); // Save initial list for reset functionality
          setLoading(false);
          fetchCustomerList();
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchQuotationList();
  }, []);
  const fetchCustomerList = async () => {
    try {
      setLoading(true);
      const loginDataFromStorage = localStorage.getItem("userDetails");
      if (loginDataFromStorage) {
        const parsedLoginData = JSON.parse(loginDataFromStorage);
        const email = parsedLoginData.reseller_email;
        const customers = await getCustomerlist(email);
        setCustomerList(customers);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    const filteredList = initialUserList.filter((quotation) =>
      quotation.domain_name.toLowerCase().includes(value.toLowerCase())
    );
    setQuotationList(filteredList);
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const endDateWithTime = new Date(date);
      endDateWithTime.setHours(23, 59, 59, 999); // Set time to 23:59:59
      setEndDate(endDateWithTime);
    } else {
      setEndDate(null);
    }
  };

  const filterByDateRange = () => {
    if (startDate && endDate) {
      // Check if endDate is before startDate
      if (endDate < startDate) {
        setDateError("End date cannot be less than the start date."); // Set error message
        return;
      }

      setDateError(""); // Clear error message if dates are valid

      const filteredList = quotationList.filter((quot) => {
        console.log(quot.created_date);
        const quotDate = new Date(quot.created_date);
        return quotDate >= startDate && quotDate <= endDate;
      });
      setQuotationList(filteredList);
    } else {
      setDateError(""); // Clear error message if no dates are selected

      setQuotationList(quotationList);
    }
  };
  const handleReset = () => {
    setSearchInput("");
    setStartDate(null);
    setEndDate(null);
    setQuotationList(initialUserList);
  };

  const handleSort = (column) => {
    let sortedList = [...quotationList];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setQuotationList(sortedList);
  };

  const handleViewQuotationDetails = (quotation) => {
    setSelectedQuotationDetails(quotation);
    // setOriginalCommission(quotation.total_commission || 0);
    setShowQuotationModal(true);
    setShowVoucherInput(false);
    setVoucherCode("");
    setVoucherAmount(0);
    setVoucherApplied(false);
  };
  const handleVoucherCodeChange = (e) => {
    setVoucherCode(e.target.value);
  };
  const handleApplyVoucherCode = async () => {
    if (!voucherCode) {
      setError("Please enter the voucher code.");
      return;
    }
    if (voucherApplied) {
      // If the voucher has already been applied, show an error message
      setError("Voucher code has already been applied.");
      return;
    }

    try {
      setLoading(true);
      if (voucherCode) {
        const response = await getVoucherAmount(
          parsedLoginData.reseller_email,
          voucherCode,
          selectedQuotationDetails.quotation_id
        );
        const voucherDetails = await response.json();
        if (voucherDetails.data.pr_flag === 1) {
          // Voucher is valid and can be applied
          setVoucherAmount(voucherDetails.data.pr_voucheramount);
          setVoucherType(voucherDetails.data.pr_vouchertype);
          setTotalCommission(
            totalCommission -
              originalCommission +
              voucherDetails.data.pr_voucheramount
          );
          setVoucherApplied(true); // Mark the voucher as applied
          setError(""); // Clear any previous errors
        } else if (voucherDetails.data.pr_flag === 2) {
          // Voucher is already used
          setError("Voucher Code is already used.");
        } else if (voucherDetails.data.pr_flag === 3) {
          // Voucher not found
          setError("Voucher Code not found.");
        } else if (voucherDetails.data.pr_flag === 0) {
          setError("Voucher Code is expired.");
        }
      } else {
        setVoucherAmount(0);
        setTotalCommission(originalCommission);
        setError(""); // Clear any previous errors
      }
    } catch (error) {
      console.error("Error applying voucher code:", error.message);
      setError(
        "An error occurred while applying the voucher code. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalCommission = (option) => {
    const baseCommission =
      selectedQuotationDetails.concatenated_products.reduce(
        (total, product) => total + (parseFloat(product[4]) || 0),
        0
      );

    if (option === "regular") {
      return baseCommission + voucherAmount;
    } else if (option === 0) {
      return baseCommission;
    }

    return baseCommission; // Default return if option is neither "regular" nor 0
  };

  const handleMapQuotation = (quotation) => {
    setSelectedQuotationDetails(quotation); // Set selectedQuotationDetails first
    setSelectedQuotationId(quotation.quotation_id); // Set selectedQuotationId
    setShowMapQuotationModal(true);
    fetchCaseNames(quotation.reseller_id); // Call fetchCaseNames with quotation's reseller_id
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const handlePlaceOrder = async () => {
    try {
      setLoading(true);
      if (selectedQuotationDetails) {
        const {
          quotation_id,
          plan_type,
          plan_duration,
          reseller_email,
          case_name,
        } = selectedQuotationDetails;
        if (!case_name) {
          window.alert(
            "Please map a case to this quotation before placing the order."
          );
          return;
        }

        // if(!voucherCode){
        //   setVoucherCode("None")
        // }
        // Example of sending data to an API
        // await placeOrder({ quotation_id, plan_type, plan_duration, reseller_email });
        const response = await placeOrder(
          quotation_id,
          plan_type,
          plan_duration,
          reseller_email,
          parsedLoginData.reseller_type,
          voucherCode
        );
        if (response.status === 200) {
          const data = await response.json();
          window.alert(data.message);
          // window.alert("Order Placed Successfully.");
          // window.location.href = "/usermaincomponent";
          setShowQuotationModal(false);
          setQuotationList(initialUserList);
        } else {
          const data = await response.json();
          window.alert(data.message);
        }
        setShowQuotationModal(false);
      }
    } catch (error) {
      window.alert("Error for place order:", error);
    } finally {
      setLoading(false);
    }
  };
  const getStatusStyle = (status) => {
    let color = "";
    let fontWeight = "normal";
    if (status === null) {
      return { color, fontWeight };
    }
    switch (status.toLowerCase()) {
      case "pending":
        color = "orange";
        fontWeight = "bold";
        break;
      case "accepted":
        color = "green";
        fontWeight = "bold";
        break;
      case "rejected":
        color = "red";
        fontWeight = "bold";
        break;
      case "delivered":
        color = "green";
        fontWeight = "bold";
        break;
      default:
        break;
    }

    return { color, fontWeight };
  };

  const fetchCaseNames = async (reseller_id) => {
    try {
      setLoading(true);
      const cases = await getCasesnames(reseller_id);
      setCaseNames(cases);
    } catch (error) {
      console.error("Error fetching case names:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleMapQuotationSubmit = async () => {
    try {
      setLoading(true);
      if (selectedQuotationId && selectedCaseName) {
        const response = await mapQuotation(
          selectedCaseName,
          selectedQuotationId
        );
        if (response.status === 200) {
          const responsedata = await response.json();
          window.alert(responsedata.message);
          setShowMapQuotationModal(false); // Close the modal here
          setSelectedQuotationId(null);
          setShowQuotationModal(false);
          setSelectedCaseName(""); // Reset selectedCaseName after submission
          const parsedLoginData = JSON.parse(loginDataFromStorage);
          const email = parsedLoginData.reseller_email;
          const data = await getCustomerQuotationlist(email);
          setQuotationList(data);
        } else {
          const responsedata = await response.json();
          window.alert(responsedata.message);
        }
      } else {
        window.alert("Please select a case.");
      }
    } catch (error) {
      window.alert("Error mapping quotation:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleResetCommission = () => {
    // console.log(calculateTotalCommission(0));
    setTotalCommission(calculateTotalCommission(0)); // Reset commission
    setVoucherCode("");
    setVoucherAmount(0);
    setVoucherApplied(false);
    setError(""); // Clear any previous errors
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
            .date-filter-container {
            display: flex;
            align-items: center;
            margin-left: 700px;
            gap: 10px;
            margin-bottom: 20px;
            position: relative;
            z-index: 1000; /* Ensure date picker is above other elements */
          }

          .date-filter-container .form-control {
            width: 150px;
          }

          .date-filter-container button {
            margin-left: 17px;
          }
            .text-danger {
            color: #dc3545;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Quotation Lists</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search by domain name..."
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              <div className="date-filter-container" style={{ float: "right" }}>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  placeholderText="End Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <button className="btn btn-primary" onClick={filterByDateRange}>
                  Filter
                </button>
              </div>
            </div>
            {dateError && <div className="text-danger mt-2">{dateError}</div>}
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th style={thStyles}>Quotation ID</th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("customer_name")}
                    >
                      Customer Name
                      {sortColumn === "customer_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("domain_name")}
                    >
                      Domain Name
                      {sortColumn === "domain_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th style={thStyles}>Created Date</th>
                    {/* <th style={thStyles}>Reseller ID</th> */}
                    <th style={thStyles}>Quotation Expiry</th>
                    <th style={thStyles}>Order Status</th>
                    {/* <th style={thStyles}>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {quotationList.map((quotation) => (
                    <tr
                      key={quotation.quotation_id}
                      onClick={() => handleViewQuotationDetails(quotation)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{quotation.quotation_id}</td>
                      <td>{quotation.customer_name}</td>
                      <td>{quotation.domain_name}</td>
                      <td>
                        {new Date(quotation.created_date).toLocaleDateString()}
                      </td>
                      {/* <td>{quotation.reseller_id}</td> */}
                      <td>
                        {new Date(
                          quotation.quotation_expiry
                        ).toLocaleDateString()}
                      </td>
                      <td style={getStatusStyle(quotation.order_status)}>
                        {quotation.order_status}
                      </td>
                      {/* <td>{renderActionButtons(quotation)}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {selectedQuotationDetails && (
        <Modal
          show={showQuotationModal}
          onHide={() => setShowQuotationModal(false)}
          size="xl"
          style={{ zIndex: 3000 }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Quotation Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="customerName"
                      className="col-sm-3 col-form-label"
                    >
                      Customer Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="customerName"
                        value={selectedQuotationDetails.customer_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_customerdomainname"
                      className="col-sm-3 col-form-label"
                    >
                      Domain Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_customerdomainname"
                        value={selectedQuotationDetails.domain_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_plantype"
                      className="col-sm-3 col-form-label"
                    >
                      Plan Type
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_plantype"
                        value={selectedQuotationDetails.plan_type}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_planduration"
                      className="col-sm-3 col-form-label"
                    >
                      Plan Duration
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_planduration"
                        value={selectedQuotationDetails.plan_duration}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="in_reseller_email"
                      className="col-sm-3 col-form-label"
                    >
                      Reseller Email
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="in_reseller_email"
                        value={selectedQuotationDetails.reseller_email}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="in_reseller_name"
                      className="col-sm-3 col-form-label"
                    >
                      Reseller Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="in_reseller_name"
                        value={selectedQuotationDetails.pr_resellername}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                {/* Add other fields similarly */}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="in_case_name"
                      className="col-sm-3 col-form-label"
                    >
                      Case Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="in_case_name"
                        value={selectedQuotationDetails.case_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <h5>Product Details</h5>
                  <div
                    style={{
                      maxHeight: "160px",
                      overflowY: "auto",
                      border: "2px solid #000",
                    }}
                  >
                    <table className="table">
                      <thead>
                        <tr
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 100,
                            backgroundColor: "#dee2e6",
                          }}
                        >
                          <th>Product Name</th>
                          <th>User Count</th>
                          <th>Actual Price</th>
                          <th>Final Price</th>
                          {parsedLoginData.reseller_type !== "Direct" && (
                            <th>Commision Amount</th>
                          )}
                        </tr>
                      </thead>
                      <tbody style={{ paddingTop: "30px" }}>
                        {selectedQuotationDetails.concatenated_products.map(
                          (product, index) => {
                            const productName = product[0];
                            const userCount = product[1];
                            const actualPrice = product[2];
                            const finalPrice = product[3];
                            return (
                              <tr key={index}>
                                <td>{productName}</td>
                                <td>{userCount}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={actualPrice}
                                    // onChange={(e) =>
                                    //   handlePriceChange(e, index, "actual")
                                    // }
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={finalPrice}
                                    // onChange={(e) =>
                                    //   handlePriceChange(e, index, "final")
                                    // }
                                    readOnly
                                  />
                                </td>
                                <td>
                                  {parsedLoginData.reseller_type !==
                                    "Direct" && (
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={product[4]}
                                      // onChange={(e) =>
                                      //   handlePriceChange(e, index, "actual")
                                      // }
                                      readOnly
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtogst"
                      className="col-sm-3 col-form-label"
                    >
                      Total
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtogst"
                        value={selectedQuotationDetails.final_price_wto_gst}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                {parsedLoginData.reseller_type !== "Direct" && (
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="pr_commission_amount"
                        className="col-sm-3 col-form-label"
                      >
                        Total Commission
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="pr_commission_amount"
                          value={totalCommission.toFixed(2)}
                          readOnly
                        />
                        {voucherAmount > 0 && !error && (
                          <div className="text-success mt-2">
                            <p style={{ marginBottom: -40 }}>
                              Voucher applied successfully! You received a
                              discount of
                              <strong>
                                {" "}
                                {voucherType === "percentage"
                                  ? `${voucherAmount.toFixed(2)}%`
                                  : `₹${voucherAmount.toFixed(2)}`}
                              </strong>
                              .
                            </p>
                          </div>
                        )}
                        {error && (
                          <div className="text-danger mt-2">
                            <p style={{ marginBottom: -40 }}>{error}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {parsedLoginData.reseller_type === "Indirect" &&
                  selectedQuotationDetails &&
                  selectedQuotationDetails.order_status !== "Delivered" &&
                  selectedQuotationDetails.case_name !== null && (
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          htmlFor="voucherCode"
                          className="col-sm-3 col-form-label"
                        >
                          Voucher Code
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            id="voucherCode"
                            value={voucherCode}
                            onChange={handleVoucherCodeChange}
                          />
                          <div className="btn-group mt-2">
                            <Button
                              variant="primary"
                              onClick={handleApplyVoucherCode}
                            >
                              Redeem
                            </Button>
                            <Button
                              variant="secondary"
                              onClick={handleResetCommission}
                              className="ml-2"
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {parsedLoginData.reseller_type !== "Indirect" && (
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="pr_pricewtogst"
                        className="col-sm-3 col-form-label"
                      >
                        Partner Discount
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="pr_pricewtogst"
                          value={selectedQuotationDetails.discount}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {parsedLoginData.reseller_type !== "Indirect" && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="pr_pricewtogst"
                        className="col-sm-3 col-form-label"
                      >
                        Discount Amount
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="pr_pricewtogst"
                          value={selectedQuotationDetails.discount_amt}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="pr_pricewtogst"
                        className="col-sm-3 col-form-label"
                      >
                        Discounted Amount
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="pr_pricewtogst"
                          value={selectedQuotationDetails.discounted_price}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectedQuotationDetails.case_name !== null ||
                (selectedQuotationDetails.additional_deal_discount !== null && (
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label
                          for="pr_pricewtgst"
                          class="col-sm-3 col-form-label"
                        >
                          Additional Deal discount
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            id="additional_deal_discount"
                            value={
                              selectedQuotationDetails.additional_deal_discount +
                              "%"
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label
                          for="pr_pricewtgst"
                          class="col-sm-3 col-form-label"
                        >
                          Final Discounted Price
                        </label>
                        <div class="col-sm-9">
                          <input
                            type="text"
                            class="form-control"
                            id="final_discounted_price"
                            value={
                              selectedQuotationDetails.final_discounted_price
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtgst"
                      className="col-sm-3 col-form-label"
                    >
                      GST Amount(18%)
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtgst"
                        value={selectedQuotationDetails.GST_ammount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtgst"
                      className="col-sm-3 col-form-label"
                    >
                      Price with GST
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtgst"
                        value={selectedQuotationDetails.final_price_wt_gst}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowQuotationModal(false)}
            >
              Close
            </Button>
            {selectedQuotationDetails &&
              selectedQuotationDetails.order_status !== "Delivered" &&
              ((new Date(selectedQuotationDetails.quotation_expiry) >
                new Date() ||
                selectedQuotationDetails.customer_name !== null ||
                selectedQuotationDetails.case_name !== null) &&
              selectedQuotationDetails.customer_name !== null &&
              selectedQuotationDetails.case_name !== null ? (
                <Button
                  variant="primary"
                  onClick={() => handlePlaceOrder(selectedQuotationDetails)}
                >
                  Place Order
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => handleMapQuotation(selectedQuotationDetails)}
                >
                  Map Quotation
                </Button>
              ))}
          </Modal.Footer>
        </Modal>
      )}
      {selectedQuotationId && (
        <Modal
          show={showMapQuotationModal}
          onHide={() => setShowMapQuotationModal(false)}
          size="sm"
          style={{ zIndex: 4000 }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Map Quotation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Select Case</Form.Label>
                {/* Replace Form.Control with Select */}
                <Select
                  options={caseNames.map((caseName) => ({
                    value: caseName,
                    label: caseName,
                  }))}
                  onChange={(selectedOption) =>
                    setSelectedCaseName(selectedOption.value)
                  }
                  placeholder="Select Case..."
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowMapQuotationModal(false)}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleMapQuotationSubmit}>
              Map Quotation
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
};
export default UserQuotationDetails;
