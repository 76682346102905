import React, { Fragment, useState, useEffect } from "react";
import { getAdminsInvoiceLists } from "../../utils/api";
import { Modal } from "react-bootstrap";

import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AdminInvoiceDetails = () => {
  const [invoices, setInvoices] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [initialInvoicesList, setInitialInvoicesList] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getAdminsInvoiceLists();
        setInvoices(data);
        setInitialInvoicesList(data);
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    const filteredList = initialInvoicesList.filter((invoice) => {
      if (invoice && invoice.invoice_no) {
        return invoice.invoice_no.toLowerCase().includes(value.toLowerCase());
      } else {
        return false; // Skip this user if domain_name is undefined
      }
    });
    console.log("Filtered List:", filteredList);
    setInvoices(filteredList);
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const endDateWithTime = new Date(date);
      endDateWithTime.setHours(23, 59, 59, 999); // Set time to 23:59:59
      setEndDate(endDateWithTime);
    } else {
      setEndDate(null);
    }
  };
  const filterByDateRange = () => {
    console.log(startDate, endDate);
    if (startDate && endDate) {
      // Check if endDate is before startDate
      if (endDate < startDate) {
        setDateError("End date cannot be less than the start date."); // Set error message
        return;
      }

      setDateError(""); // Clear error message if dates are valid

      const filteredList = initialInvoicesList.filter((invoice) => {
        const invoiceDate = new Date(invoice.invoice_date);
        return invoiceDate >= startDate && invoiceDate <= endDate;
      });
      setInvoices(filteredList);
    } else {
      setDateError(""); // Clear error message if no dates are selected
      setInvoices(initialInvoicesList);
    }
  };

  const handleReset = () => {
    setSearchInput("");
    setStartDate("");
    setEndDate("");
    setInvoices(initialInvoicesList);
  };
  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const handleViewClick = (invoice) => {
    setSelectedInvoice(invoice);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedInvoice(null);
  };
  const handleSort = (column) => {
    let sortedList = [...initialInvoicesList];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setInitialInvoicesList(sortedList);
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
            .date-filter-container {
            display: flex;
            align-items: center;
            margin-left: 700px;
            gap: 10px;
            margin-bottom: 20px;
            position: relative;
            z-index: 1000; /* Ensure date picker is above other elements */
          }

          .date-filter-container .form-control {
            width: 150px;
          }

          .date-filter-container button {
            margin-left: 17px;
          }
            .text-danger {
            color: #dc3545;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Invoice List</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search by invoice number..."
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              <div className="date-filter-container" style={{ float: "right" }}>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  placeholderText="End Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <button className="btn btn-primary" onClick={filterByDateRange}>
                  Filter
                </button>
              </div>
            </div>
            {dateError && <div className="text-danger mt-2">{dateError}</div>}
            <br></br>
            <div
              className="table-responsive"
              style={{ maxHeight: "450px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    {/* <th style={thStyles}>Cust ID</th> */}
                    <th style={thStyles}>Invoice Number</th>
                    <th style={thStyles}>Invoice Date</th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("domain_name")}
                    >
                      Domain Name
                      {sortColumn === "domain_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("company_name")}
                    >
                      Reseller Company Name
                      {sortColumn === "company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th style={thStyles}>Payment Status</th>
                    {/* <th style={thStyles}>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice) => (
                    <tr
                      key={invoice.invoice_id}
                      onClick={() => handleViewClick(invoice)}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <td>{user.cust_id}</td> */}
                      <td>{invoice.invoice_no}</td>
                      <td>
                        {new Date(invoice.invoice_date).toLocaleDateString()}
                      </td>
                      <td>{invoice.domain_name}</td>
                      <td>{invoice.company_name}</td>
                      <td>{invoice.payment_status}</td>
                      {/* <td>{renderActionButtons(invoice)}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {selectedInvoice && (
        <Modal show={showModal} onHide={handleCloseModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>
              Invoice Details{" - "}
              {selectedInvoice.invoice_no}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Domain Name:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="customer_name"
                        value={selectedInvoice.domain_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Reseller Company Name:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="domain_name"
                        value={selectedInvoice.company_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Invoice Date:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="customer_address"
                        value={selectedInvoice.invoice_date}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Case Name:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="customer_address"
                        value={selectedInvoice.case_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <h5>Product Details</h5>
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      border: "2px solid #000",
                    }}
                  >
                    <table className="table">
                      <thead>
                        <tr
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 100,
                            backgroundColor: "#dee2e6",
                          }}
                        >
                          <th>Product Name</th>
                          <th>User Counts</th>
                          <th>Unit Price</th>
                          <th>Final Price</th>
                        </tr>
                      </thead>
                      <tbody style={{ paddingTop: "30px" }}>
                        {selectedInvoice.products_details.map((prod, index) => (
                          <tr key={index}>
                            <td>{prod.skuname}</td>
                            <td>{prod.usercount}</td>
                            <td>{Number(prod.actualprice).toLocaleString()}</td>
                            <td>{Number(prod.finalprice).toLocaleString()}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Order Total:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="total"
                        value={parseInt(selectedInvoice.total).toLocaleString()}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Reseller Discounted Total:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="discounted_price"
                        value={parseInt(
                          selectedInvoice.discounted_price
                        ).toLocaleString()}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              {selectedInvoice.additional_deal_discount !== null && (
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label
                        for="pr_pricewtgst"
                        class="col-sm-4 col-form-label"
                      >
                        <strong>Additional Deal discount</strong>
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class="form-control"
                          id="additional_deal_discount"
                          value={selectedInvoice.additional_deal_discount + "%"}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label
                        for="pr_pricewtgst"
                        class="col-sm-4 col-form-label"
                      >
                        <strong>Final Discounted Price</strong>
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class="form-control"
                          id="final_discounted_price"
                          value={selectedInvoice.final_discounted_price}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Order Total (with 18 %GST):</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="final_price_wt_gst"
                        value={parseInt(
                          selectedInvoice.total_wt_gst
                        ).toLocaleString()}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>
      )}
    </Fragment>
  );
};
export default AdminInvoiceDetails;
