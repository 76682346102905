import React, { Fragment, useState, useEffect } from "react";
import { getCustomerQuotationlistAdminside } from "../../utils/api"; // Assuming you have an API function for getting customer cases list
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import { CiImport, CiExport } from "react-icons/ci";
import { Collapse, Checkbox } from "antd"; // using antd for UI, can also be custom
import { quotationdetails_modules } from "./reportModulesjson";
import { exportReports } from "../../utils/api";
const { Panel } = Collapse;
const QuotationDetails = () => {
  const [quotationList, setQuotationList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [initialUserList, setInitialUserList] = useState([]);
  const [showQuotationModal, setShowQuotationModal] = useState(false);
  const [selectedQuotationDetails, setSelectedQuotationDetails] =
    useState(null);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const admindetailsmodules = localStorage.getItem("admindetails");
  const admin_email = JSON.parse(admindetailsmodules).email;

  useEffect(() => {
    const fetchQuotationList = async () => {
      setLoading(true);
      try {
        // const loginDataFromStorage = localStorage.getItem("userDetails");
        // if (loginDataFromStorage) {
        //   const parsedLoginData = JSON.parse(loginDataFromStorage);
        //   const email = parsedLoginData.reseller_email;
        const data = await getCustomerQuotationlistAdminside();
        setQuotationList(data);
        setInitialUserList(data); // Save initial list for reset functionality
        setLoading(false);
        // }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchQuotationList();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    const filteredList = initialUserList.filter((quotation) =>
      quotation.domain_name.toLowerCase().includes(value.toLowerCase())
    );
    setQuotationList(filteredList);
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const endDateWithTime = new Date(date);
      endDateWithTime.setHours(23, 59, 59, 999); // Set time to 23:59:59
      setEndDate(endDateWithTime);
    } else {
      setEndDate(null);
    }
  };
  const filterByDateRange = () => {
    console.log(startDate, endDate);
    if (startDate && endDate) {
      // Check if endDate is before startDate
      if (endDate < startDate) {
        setDateError("End date cannot be less than the start date."); // Set error message
        return;
      }

      setDateError(""); // Clear error message if dates are valid

      const filteredList = initialUserList.filter((quot) => {
        const quotationDate = new Date(quot.created_date);
        return quotationDate >= startDate && quotationDate <= endDate;
      });
      setQuotationList(filteredList);
    } else {
      setDateError(""); // Clear error message if no dates are selected
      setQuotationList(initialUserList);
    }
  };

  const handleReset = () => {
    setSearchInput("");
    setStartDate("");
    setEndDate("");
    setQuotationList(initialUserList);
  };
  const handleViewQuotationDetails = (quotation) => {
    setSelectedQuotationDetails(quotation);
    setShowQuotationModal(true);
  };

  const handleSort = (column) => {
    let sortedList = [...initialUserList];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setInitialUserList(sortedList);
  };

  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const getStatusStyle = (status) => {
    let color = "";
    let fontWeight = "normal";
    if (status === null) {
      return { color, fontWeight };
    }
    switch (status.toLowerCase()) {
      case "pending":
        color = "orange";
        fontWeight = "bold";
        break;
      case "accepted":
        color = "green";
        fontWeight = "bold";
        break;
      case "rejected":
        color = "red";
        fontWeight = "bold";
        break;
      default:
        break;
    }

    return { color, fontWeight };
  };
  const handleCloseExportModal = () => {
    setIsModalVisible(false);
    // Reset all fields to their initial state
    setSelectedModule("");
    setSelectedOption("");
    setSelectedSubOption("");
  };
  const toggleModal = () => {
    console.log("toggle ");
    setIsModalVisible(true);
  };

  const handleModuleChange = (moduleName) => {
    setSelectedModule(moduleName);
    setSelectedOption(null); // Reset selected option when module changes
    setSelectedSubOption(null); // Reset selected sub-option
  };

  const handleOptionChange = (optionName) => {
    setSelectedOption(optionName);
    setSelectedSubOption(null); // Reset selected sub-option when option changes
  };

  const handleSubOptionChange = (subOptionName) => {
    setSelectedSubOption((prev) =>
      prev === subOptionName ? null : subOptionName
    );
  };

  const handleSubmit = async () => {
    if (!selectedOption) {
      alert("Please select at least one report type.");
      return;
    }
    console.log("Selected Module:", selectedModule);
    console.log("Selected Option:", selectedOption);
    console.log("Selected Sub-Option:", selectedSubOption);
    try {
      setLoading(true);
      const response = await exportReports(
        selectedModule,
        selectedOption ? selectedOption : null,
        selectedSubOption ? selectedSubOption : null,
        null,
        null,
        admin_email
      );
      if (response.status === 200) {
        const responsedata = await response.json();
        if (responsedata.flag_value === 1) {
          window.alert(responsedata.message);
          setSelectedModule(null);
          setSelectedOption(null);
          setSelectedSubOption(null);
          // setSelectedMonthYear(null);
          // setSelectedYear(null);
          setIsModalVisible(false);
        } else if (responsedata.flag_value === 2) {
          window.alert(responsedata.message);
        }
      } else {
        console.error("Failed to generate report:", response.statusText);
      }
    } catch (error) {
      console.error("Error generate report", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
            .date-filter-container {
            display: flex;
            align-items: center;
            margin-left: 700px;
            gap: 10px;
            margin-bottom: 20px;
            position: relative;
            z-index: 1000; /* Ensure date picker is above other elements */
          }

          .date-filter-container .form-control {
            width: 150px;
          }

          .date-filter-container button {
            margin-left: 17px;
          }
            .text-danger {
            color: #dc3545;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Quotation Lists</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search by domain name..."
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              <button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={toggleModal}
                title="Export Reseller Data"
              >
                <CiExport size={15} />
              </button>
              <div className="date-filter-container" style={{ float: "right" }}>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  placeholderText="End Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <button className="btn btn-primary" onClick={filterByDateRange}>
                  Filter
                </button>
              </div>
            </div>
            {dateError && <div className="text-danger mt-2">{dateError}</div>}

            <br></br>

            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    <th style={thStyles}>Quotation ID</th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("customer_name")}
                    >
                      Customer Name
                      {sortColumn === "customer_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("domain_name")}
                    >
                      Customer Name
                      {sortColumn === "domain_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    <th style={thStyles}>Created Date</th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("company_name")}
                    >
                      Reseller Company Name
                      {sortColumn === "company_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" /> // Default sort icon
                      )}
                    </th>
                    {/* <th style={thStyles}>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {quotationList.map((quotation) => (
                    <tr
                      key={quotation.quotation_id}
                      onClick={() => handleViewQuotationDetails(quotation)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{quotation.quotation_id}</td>
                      <td>{quotation.customer_name}</td>
                      <td>{quotation.domain_name}</td>
                      <td>
                        {new Date(quotation.created_date).toLocaleDateString()}
                      </td>
                      <td>{quotation.company_name}</td>
                      {/* <td>{renderActionButtons(quotation)}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {selectedQuotationDetails && (
        <Modal
          show={showQuotationModal}
          onHide={() => setShowQuotationModal(false)}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Quotation Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="customerName"
                      className="col-sm-3 col-form-label"
                    >
                      Customer Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="customerName"
                        value={selectedQuotationDetails.customer_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_customerdomainname"
                      className="col-sm-3 col-form-label"
                    >
                      Customer Domain Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_customerdomainname"
                        value={selectedQuotationDetails.domain_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_plantype"
                      className="col-sm-3 col-form-label"
                    >
                      Plan Type
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_plantype"
                        value={selectedQuotationDetails.plan_type}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_planduration"
                      className="col-sm-3 col-form-label"
                    >
                      Plan Duration
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_planduration"
                        value={selectedQuotationDetails.plan_duration}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="in_reseller_email"
                      className="col-sm-3 col-form-label"
                    >
                      Reseller Email
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="in_reseller_email"
                        value={selectedQuotationDetails.reseller_email}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="in_reseller_name"
                      className="col-sm-3 col-form-label"
                    >
                      Reseller Name
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="in_reseller_name"
                        value={selectedQuotationDetails.reseller_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                {/* Add other fields similarly */}
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <h5>Product Details</h5>
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      border: "2px solid #000",
                    }}
                  >
                    <table className="table">
                      <thead>
                        <tr
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 100,
                            backgroundColor: "#dee2e6",
                          }}
                        >
                          <th>Product Name</th>
                          <th>User Count</th>
                          <th>Actual Price</th>
                          <th>Final Price</th>
                        </tr>
                      </thead>
                      <tbody style={{ paddingTop: "30px" }}>
                        {selectedQuotationDetails.concatenated_products.map(
                          (product, index) => {
                            const productName = product[0];
                            const userCount = product[1];
                            const actualPrice = product[2];
                            const finalPrice = product[3];
                            return (
                              <tr key={index}>
                                <td>{productName}</td>
                                <td>{userCount}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={actualPrice}
                                    // onChange={(e) =>
                                    //   handlePriceChange(e, index, "actual")
                                    // }
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={finalPrice}
                                    // onChange={(e) =>
                                    //   handlePriceChange(e, index, "final")
                                    // }
                                    readOnly
                                  />
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtogst"
                      className="col-sm-3 col-form-label"
                    >
                      Total
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtogst"
                        value={selectedQuotationDetails.final_price_wto_gst}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtogst"
                      className="col-sm-3 col-form-label"
                    >
                      Discount
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtogst"
                        value={selectedQuotationDetails.discount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtogst"
                      className="col-sm-3 col-form-label"
                    >
                      Discount Amount
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtogst"
                        value={selectedQuotationDetails.discount_amt}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtogst"
                      className="col-sm-3 col-form-label"
                    >
                      Discounted Price
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtogst"
                        value={selectedQuotationDetails.discounted_price}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              {selectedQuotationDetails.case_name !== "" && (
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label
                        for="pr_pricewtgst"
                        class="col-sm-3 col-form-label"
                      >
                        Additional Deal discount
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          id="additional_deal_discount"
                          value={
                            selectedQuotationDetails.additional_deal_discount
                              ? selectedQuotationDetails.additional_deal_discount +
                                "%"
                              : "0%"
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label
                        for="pr_pricewtgst"
                        class="col-sm-3 col-form-label"
                      >
                        Final Discounted Price
                      </label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          id="final_discounted_price"
                          value={
                            selectedQuotationDetails.final_discounted_price
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtgst"
                      className="col-sm-3 col-form-label"
                    >
                      GST Amount(18%)
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtgst"
                        value={selectedQuotationDetails.GST_ammount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      htmlFor="pr_pricewtgst"
                      className="col-sm-3 col-form-label"
                    >
                      Price with GST
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="pr_pricewtgst"
                        value={selectedQuotationDetails.final_price_wt_gst}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowQuotationModal(false)}
            >
              Close
            </Button>
            {/* <Button variant="primary" onClick={handlePlaceOrder}>
              Place Order
            </Button> */}
          </Modal.Footer>
        </Modal>
      )}
      <Modal
        title="Export Data"
        onHide={handleCloseExportModal}
        show={isModalVisible}
      >
        <Modal.Header closeButton>
          <Modal.Title>Export Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Collapse>
            {quotationdetails_modules.map((module, moduleIndex) => (
              <Panel header={module.name} key={moduleIndex}>
                <Checkbox
                  checked={selectedModule === module.name}
                  onChange={() => handleModuleChange(module.name)}
                >
                  Select Report Type
                </Checkbox>
                {selectedModule === module.name && (
                  <div style={{ paddingLeft: "20px" }}>
                    {module.options.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <Checkbox
                          onChange={() => handleOptionChange(option.name)}
                          checked={selectedOption === option.name}
                        >
                          {option.name}
                        </Checkbox>
                        {selectedOption === option.name &&
                          option.subOptions && (
                            <div style={{ paddingLeft: "20px" }}>
                              {option.subOptions.map((subOption, subIndex) => (
                                <div key={subIndex}>
                                  <Checkbox
                                    onChange={() =>
                                      handleSubOptionChange(subOption)
                                    }
                                    checked={selectedSubOption === subOption}
                                  >
                                    {subOption}
                                  </Checkbox>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )}
              </Panel>
            ))}
          </Collapse>
        </Modal.Body>
        <Modal.Footer>
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Get Report
          </Button>
          ,
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
export default QuotationDetails;
