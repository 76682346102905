import React, { Fragment, useState, useEffect } from "react";
import { getCustomerorderslistforadmin } from "../../utils/api";
import { Modal, Button } from "react-bootstrap";

import { FaSortUp, FaSortDown, FaSort } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiImport, CiExport } from "react-icons/ci";
import { Collapse, Checkbox } from "antd"; // using antd for UI, can also be custom
import { orderdetails_modules } from "./reportModulesjson";
import { exportReports } from "../../utils/api";
import { registerLocale } from "react-datepicker";
import { subYears } from "date-fns";
const { Panel } = Collapse;

const OrderLists = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);
  const admindetailsmodules = localStorage.getItem("admindetails");
  const admin_email = JSON.parse(admindetailsmodules).email;
  const [selectedMonthYear, setSelectedMonthYear] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  // useEffect(() => {
  //   document.title = "Order Lists"; // Set the document title
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // const loginDataFromStorage = localStorage.getItem("userDetails");
        // if (loginDataFromStorage) {
        //   const parsedLoginData = JSON.parse(loginDataFromStorage);
        //   setEmail(parsedLoginData.reseller_email);
        const response = await getCustomerorderslistforadmin();
        setOrders(response.data.data_result);
        setFilteredOrders(response.data.data_result);
        // }
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearchInputChange = (value) => {
    setSearchInput(value);
    const filteredList = orders.filter((order) => {
      if (order && order.domain_name) {
        return order.domain_name.toLowerCase().includes(value.toLowerCase());
      } else {
        return false;
      }
    });
    setFilteredOrders(filteredList);
  };

  const handleEndDateChange = (date) => {
    if (date) {
      const endDateWithTime = new Date(date);
      endDateWithTime.setHours(23, 59, 59, 999); // Set time to 23:59:59
      setEndDate(endDateWithTime);
    } else {
      setEndDate(null);
    }
  };
  const filterByDateRange = () => {
    console.log(startDate, endDate);
    if (startDate && endDate) {
      // Check if endDate is before startDate
      if (endDate < startDate) {
        setDateError("End date cannot be less than the start date."); // Set error message
        return;
      }

      setDateError(""); // Clear error message if dates are valid

      const filteredList = filteredOrders.filter((order) => {
        const orderDate = new Date(order.order_date);
        return orderDate >= startDate && orderDate <= endDate;
      });
      setFilteredOrders(filteredList);
    } else {
      setDateError(""); // Clear error message if no dates are selected
      setFilteredOrders(orders);
    }
  };

  const handleReset = () => {
    setSearchInput("");
    setFilteredOrders(orders);
  };

  const handleViewClick = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrder(null);
  };
  const handleSort = (column) => {
    let sortedList = [...filteredOrders];
    if (sortColumn === column) {
      sortedList.reverse(); // Toggle the sorting order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      sortedList.sort((a, b) => {
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
      setSortColumn(column);
      setSortOrder("asc");
    }
    setFilteredOrders(sortedList);
  };
  const thStyles = {
    position: "sticky",
    top: 0,
    backgroundColor: "#f1f1f1",
    zIndex: 100,
  };
  const handleCloseExportModal = () => {
    setIsModalVisible(false);
    // Reset all fields to their initial state
    setSelectedModule("");
    setSelectedOption("");
    setSelectedSubOption("");
  };
  const toggleModal = () => {
    console.log("toogle");
    setIsModalVisible(true);
  };

  const handleModuleChange = (moduleName) => {
    setSelectedModule(moduleName);
    setSelectedOption(null); // Reset selected option when module changes
    setSelectedSubOption(null); // Reset selected sub-option
  };

  const handleOptionChange = (optionName) => {
    setSelectedOption(optionName);
    setSelectedSubOption(null); // Reset selected sub-option when option changes
  };

  const handleSubOptionChange = (subOptionName) => {
    setSelectedSubOption((prev) =>
      prev === subOptionName ? null : subOptionName
    );
  };
  const handleSubmit = async () => {
    if (!selectedOption) {
      alert("Please select at least one report type.");
      return;
    }
    console.log("Selected Module:", selectedModule);
    console.log("Selected Option:", selectedOption);
    console.log("Selected Sub-Option:", selectedSubOption);
    try {
      setLoading(true);
      const response = await exportReports(
        selectedModule,
        selectedOption ? selectedOption : null,
        selectedSubOption ? selectedSubOption : null,
        selectedMonthYear ? selectedMonthYear : null,
        selectedYear ? selectedYear : null,
        admin_email
      );
      if (response.status === 200) {
        const responsedata = await response.json();
        if (responsedata.flag_value === 1) {
          window.alert(responsedata.message);
          setSelectedModule(null);
          setSelectedOption(null);
          setSelectedSubOption(null);
          setSelectedMonthYear(null);
          setSelectedYear(null);
          setIsModalVisible(false);
        } else if (responsedata.flag_value === 2) {
          window.alert(responsedata.message);
        }
      } else {
        console.error("Failed to generate report:", response.statusText);
      }
    } catch (error) {
      console.error("Error generate report", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <style>
        {`
          .sortable-header {
            cursor: pointer;
            position: relative;
          }

          .sort-icon {
            margin-left: 5px;
            color: #6c757d; /* Default color */
            transition: color 0.2s ease;
          }

          .sortable-header:hover .sort-icon {
            color: #007bff; /* Change this to your preferred hover color */
          }

          .table-responsive {
            max-height: 500px;
            overflow-y: auto;
          }
            .date-filter-container {
            display: flex;
            align-items: center;
            margin-left: 700px;
            gap: 10px;
            margin-bottom: 20px;
            position: relative;
            z-index: 1000; /* Ensure date picker is above other elements */
          }

          .date-filter-container .form-control {
            width: 150px;
          }

          .date-filter-container button {
            margin-left: 17px;
          }
            .text-danger {
            color: #dc3545;
          }
        `}
      </style>
      {loading && (
        <div className="loader-container">
          <div className="loader">
            <div className="tick"></div>
          </div>
        </div>
      )}
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="mb-1">Order List</h4>
            <br />
            <div className="search-container mb-3">
              <input
                type="text"
                placeholder="Search by domain name..."
                value={searchInput}
                onChange={(e) => handleSearchInputChange(e.target.value)}
                style={{ float: "left" }}
              />
              <button
                className="btn btn-primary ml-2"
                onClick={handleReset}
                style={{ float: "left" }}
              >
                Reset
              </button>
              <button
                className="btn btn-primary ml-2"
                style={{ float: "right" }}
                onClick={toggleModal}
                title="Export Reseller Data"
              >
                <CiExport size={15} />
              </button>
              <div className="date-filter-container" style={{ float: "right" }}>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  placeholderText="End Date"
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                />
                <button className="btn btn-primary" onClick={filterByDateRange}>
                  Filter
                </button>
              </div>
            </div>
            {dateError && <div className="text-danger mt-2">{dateError}</div>}
            <br></br>
            <div
              className="table-responsive"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              <table className="table table-hover sticky-header">
                <thead>
                  <tr>
                    {/* <th style={thStyles}>Order ID</th> */}
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("reseller_name")}
                    >
                      Reseller Name
                      {sortColumn === "reseller_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th
                      style={thStyles}
                      className="sortable-header"
                      onClick={() => handleSort("domain_name")}
                    >
                      Customer Domain Name
                      {sortColumn === "domain_name" ? (
                        sortOrder === "asc" ? (
                          <FaSortUp className="sort-icon" />
                        ) : (
                          <FaSortDown className="sort-icon" />
                        )
                      ) : (
                        <FaSort className="sort-icon" />
                      )}
                    </th>
                    <th style={thStyles}>Order Date</th>
                    <th style={thStyles}>Total Price (with GST)</th>
                    {/* <th style={thStyles}>Reseller ID</th> */}
                    <th style={thStyles}>Case Name</th>
                    <th style={thStyles}>Payment Status</th>
                    {/* <th style={thStyles}>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredOrders.map((order) => (
                    <tr
                      key={order.order_id}
                      onClick={() => handleViewClick(order)}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <td>{order.order_id}</td> */}
                      <td>{order.reseller_name}</td>
                      <td>{order.domain_name}</td>
                      <td>{new Date(order.order_date).toLocaleDateString()}</td>
                      <td>{order.final_price_wt_gst.toLocaleString()}</td>{" "}
                      {/* Format the price */}
                      {/* <td>{order.reseller_id}</td> */}
                      <td>{order.case_name}</td>
                      <td>Null</td>
                      {/* <td>{renderActionButtons(order)}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {selectedOrder && (
        <Modal show={showModal} onHide={handleCloseModal} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Order Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="form-sample">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Order ID:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="order_id"
                        value={selectedOrder.order_id}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Customer Name:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="customer_name"
                        value={selectedOrder.customer_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Domain Name:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="domain_name"
                        value={selectedOrder.domain_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Customer Address:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="customer_address"
                        value={selectedOrder.customer_address}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Order Date:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="order_date"
                        value={new Date(
                          selectedOrder.order_date
                        ).toLocaleString()}
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Customer Company Name:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="customer_companyname"
                        value={selectedOrder.customer_companyname}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Case Name:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="case_name"
                        value={selectedOrder.case_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Reseller Name:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="reseller_id"
                        value={selectedOrder.reseller_name}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Plan Type:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="plan_type"
                        value={selectedOrder.plan_type}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Plan Duration:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="plan_duration"
                        value={selectedOrder.plan_duration}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <h5>Product Details</h5>
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      border: "2px solid #000",
                    }}
                  >
                    <table className="table">
                      <thead>
                        <tr
                          style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 100,
                            backgroundColor: "#dee2e6",
                          }}
                        >
                          <th>Order Product ID</th>
                          <th>Product Name</th>
                          <th>User Counts</th>
                          <th>Unit Price</th>
                          <th>Final Price</th>
                        </tr>
                      </thead>
                      <tbody style={{ paddingTop: "30px" }}>
                        {selectedOrder.prod_details.map((prod, index) => (
                          <tr key={index}>
                            <td>{prod.order_prodid}</td>
                            <td>{prod.product_name}</td>
                            <td>{prod.user_count}</td>
                            <td>{Number(prod.unit_price).toLocaleString()}</td>
                            <td>{Number(prod.final_price).toLocaleString()}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Total Price:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="final_price_wto_gst"
                        value={selectedOrder.total.toLocaleString()}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Reseller Discount:</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="reseller_discount"
                        value={selectedOrder.discount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              {selectedOrder.deal_discount !== "" && (
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        <strong>Deal Discount</strong>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="deal_discount"
                          value={
                            selectedOrder.deal_discount
                              ? selectedOrder.deal_discount + "%"
                              : "0%"
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label className="col-sm-4 col-form-label">
                        <strong>Final Discounted Price</strong>
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="final_discounted_price"
                          value={selectedOrder.final_discounted_price}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      <strong>Total Price (with GST 18%):</strong>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="final_price_wt_gst"
                        value={selectedOrder.final_price_wt_gst.toLocaleString()}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>
      )}
      <Modal
        title="Export Data"
        onHide={handleCloseExportModal}
        show={isModalVisible}
      >
        <Modal.Header closeButton>
          <Modal.Title>Export Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Collapse>
            {orderdetails_modules.map((module, moduleIndex) => (
              <Panel header={module.name} key={moduleIndex}>
                <Checkbox
                  checked={selectedModule === module.name}
                  onChange={() => handleModuleChange(module.name)}
                >
                  Select Report Type
                </Checkbox>
                {selectedModule === module.name && (
                  <div style={{ paddingLeft: "20px" }}>
                    {module.options.map((option, optionIndex) => (
                      <div key={optionIndex}>
                        <Checkbox
                          onChange={() => handleOptionChange(option.name)}
                          checked={selectedOption === option.name}
                        >
                          {option.name}
                        </Checkbox>
                        {selectedOption === option.name &&
                          option.subOptions && (
                            <div style={{ paddingLeft: "20px" }}>
                              {option.subOptions.map((subOption, subIndex) => (
                                <div key={subIndex}>
                                  <Checkbox
                                    onChange={() =>
                                      handleSubOptionChange(subOption)
                                    }
                                    checked={selectedSubOption === subOption}
                                  >
                                    {subOption}
                                  </Checkbox>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                )}
                <br></br>
                {selectedOption === "Get Sales by Month" && (
                  <>
                    <h4 className="card-title">Select Month and Year</h4>
                    <DatePicker
                      selected={selectedMonthYear}
                      onChange={(date) => setSelectedMonthYear(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      locale="en-GB"
                      maxDate={new Date()}
                      placeholderText="Select Month and Year"
                      className="form-control"
                      popperPlacement="bottom"
                      popperModifiers={[
                        {
                          name: "preventOverflow",
                          options: {
                            boundary: "viewport",
                          },
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ]}
                      withPortal // Forces the calendar to open in a portal
                    />
                  </>
                )}

                {selectedOption === "Get Sales by Year" && (
                  <>
                    <h4 className="card-title">Select Year</h4>
                    <DatePicker
                      selected={selectedYear}
                      onChange={(date) => setSelectedYear(date)}
                      dateFormat="yyyy"
                      showYearPicker
                      locale="en-GB"
                      minDate={subYears(new Date(), 20)} // Adjust for minimum year range
                      maxDate={new Date()}
                      placeholderText="Select Year"
                      className="form-control"
                      popperPlacement="bottom"
                      popperModifiers={[
                        {
                          name: "preventOverflow",
                          options: {
                            boundary: "viewport",
                          },
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ]}
                      withPortal // Forces the calendar to open in a portal
                    />
                  </>
                )}
              </Panel>
            ))}
          </Collapse>
        </Modal.Body>
        <Modal.Footer>
          <Button key="submit" type="primary" onClick={handleSubmit}>
            Get Report
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
export default OrderLists;
