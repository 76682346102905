// modulesData.js
export const reseller_details_modules = [
  {
    name: "Reseller Details",
    options: [
      {
        name: "Get Resellers by Account Status",
        subOptions: [
          "Active Resellers",
          "Rejected Resellers",
          "Pending Approval",
        ],
      },
      { name: "Get Resellers with Current Partner Discount" },
      {
        name: "Get KYC details of Resellers",
        subOptions: [
          "Pending Resellers",
          "Approved Resellers",
          "Rejected Resellers",
          "Permanently Rejected Resellers",
        ],
      },
      { name: "Sales by Resellers" },
      { name: "Get Top 10 Resellers Sales by Month" },
      { name: "Get Top 10 Resellers Sales by Year" },
      { name: "Get Resellers Profits List" },
    ],
  },
];
export const orderdetails_modules = [
  {
    name: "Sales Details",
    options: [
      { name: "Get Sales by Month" },
      { name: "Get Sales by Year" },

      {
        name: "Sales by Products",
        subOptions: [
          "Jumpcloud",
          "Spin_ai",
          "Atera",
          "Patronum",
          "Florbs IO",
          "1password",
          "Scale Dino",
          "bow.chat",
        ],
      },
      { name: "Get Product Wise Reseller Count" },
    ],
  },
];
export const casedetails_modules = [
  {
    name: "Case Details",
    options: [
      {
        name: "Get Cases report by Status",
        subOptions: ["Open", "Closed", "Pending", "Rejected"],
      },
      { name: "Get Case Registered by Month" },
      { name: "Get Case Registered by Year" },
    ],
  },
];
export const quotationdetails_modules = [
  {
    name: "Quotation Details",
    options: [
      {
        name: "Get Quotation by Status",
        subOptions: ["Pending Quotation", "Completed Quotation"],
      },
    ],
  },
];
